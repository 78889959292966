import * as Icons from "../../../shared/components/icons";
import AddSubject from "../forms/AddSubject";
import UndismissibleModal from "../modals/UndismissibleModal";

import { accountType } from "../../../constants/accountType";
import { useAuth } from "../../../store/authContext";

export const AddSubjectButton = ({ showModal = false, onClose }) => {
  const { user } = useAuth();
  const userType = user?.userType;

  const addSubjectButtonContent = () => {
    return (
      <>
        <UndismissibleModal
          showModal={showModal}
          showCloseButton={false}
          maxHeight="200px"
        >
          <AddSubject closeModal={onClose} />
        </UndismissibleModal>
        <button
          className={
            "inline-flex h-[40px] w-[156px] items-center justify-center whitespace-nowrap rounded-[5px] px-[15px] text-[14px] font-semibold text-white"
          }
          style={{ background: "#437EF7" }}
          onClick={onClose}
        >
          <span className="mr-[12px] inline-flex h-[14px] w-[14px]">
            <Icons.Plus className="flex-0 h-full" />
          </span>
          Add Subject
        </button>
      </>
    );
  };

  return (
    <>{userType === accountType.SCHOOL ? addSubjectButtonContent() : null}</>
  );
};
