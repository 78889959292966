import * as React from "react";

const SvgEye = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.145 4.316C5.661 1.632 8.62 0 12 0c3.38 0 6.339 1.632 8.855 4.316a20.491 20.491 0 0 1 2.927 4.022l.112.215a1 1 0 0 1 0 .894c-.109.219-.293.551-.55.968l-.238.378a20.483 20.483 0 0 1-2.252 2.89C18.34 16.368 15.38 18 12 18c-3.38 0-6.339-1.633-8.855-4.316A20.485 20.485 0 0 1 .218 9.662l-.112-.215a1 1 0 0 1 0-.894c.109-.219.293-.551.55-.968l.238-.378a20.493 20.493 0 0 1 2.251-2.89Zm16.25 1.368C17.224 3.368 14.745 2 12 2 9.255 2 6.776 3.368 4.605 5.684a18.513 18.513 0 0 0-2.31 3.056L2.14 9l.154.26.134.22.145.227a18.52 18.52 0 0 0 2.03 2.61C6.777 14.632 9.255 16 12 16c2.745 0 5.224-1.367 7.395-3.684a18.52 18.52 0 0 0 2.31-3.056l.153-.26-.153-.26-.134-.22-.145-.227a18.513 18.513 0 0 0-2.03-2.61Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm6 0a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEye;
