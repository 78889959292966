interface PrimaryButtonUiProps {
  title: string;
  onClick: () => void;
}

const PrimaryButtonUi = ({ title, onClick }: PrimaryButtonUiProps) => {
  return (
    <button
      onClick={onClick}
      className="rounded-[8px] w-full bg-blue p-[14px] text-[16px] font-bold text-white"
    >
      {title}
    </button>
  );
};

export default PrimaryButtonUi;
