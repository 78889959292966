import { useAuth } from "../../../../store/authContext";
import StepCheckHeadUi from "./StepCheckHeadUi";
import StepCheckTileUi from "./StepCheckTileUi";

const PrerequisiteSteps = () => {
  const { user } = useAuth();

  const {
    areSubjectsCreated,
    areSubjectsAssignedToClasses,
    areExamTypesCreated,
  } = user || {};

  return (
    <div className="flex flex-col">
      <span className="text-[28px] font-semibold text-black-shade10">
        Good morning, Admin!
      </span>
      <span className="text-[16px] font-normal text-grey-shade10">
        Follow these steps to get started
      </span>

      <div className="mt-[16px] h-full w-full rounded-[10px] bg-white pt-[12px]">
        <StepCheckHeadUi />
        <StepCheckTileUi
          step="Step 1"
          actionTitle="Create subjects"
          actionRoute={areSubjectsCreated ? "" : "/academic/subjects"}
          isChecked={areSubjectsCreated}
        />
        <StepCheckTileUi
          step="Step 2"
          actionTitle="Assign subjects"
          actionRoute={
            areSubjectsCreated && !areSubjectsAssignedToClasses
              ? "/academic/class"
              : ""
          }
          isChecked={areSubjectsAssignedToClasses}
        />
        <StepCheckTileUi
          step="Step 3"
          actionTitle="Create exam types"
          actionRoute={
            areSubjectsCreated && areSubjectsAssignedToClasses
              ? "/assessments/exam-types"
              : ""
          }
          isChecked={areExamTypesCreated}
        />
      </div>
    </div>
  );
};

export default PrerequisiteSteps;
