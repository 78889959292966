export const SUPPORTED_IMAGE_FORMAT = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

export const SUPPORT_PDF = ["application/pdf"];

export const IMAGE_SIZE_LIMIT = 7 * (1024 * 1024);

export const SUPPORTED_SIGNATURE_FORMAT = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];
