const StepCheckHeadUi = () => {
  return (
    <div className="grid grid-cols-12 border-b-[1px] border-grey-shade15 pb-[13px]">
      {/* Steps */}
      <div className="col-span-4 ml-[24px]">
        <span className="text-[13px] font-bold text-grey-shade10">Steps</span>
      </div>

      {/* Action */}
      <div className="col-span-6">
        <span className="text-[13px] font-bold text-grey-shade10">Action</span>
      </div>

      {/* Progress */}
      <div className="col-span-2 flex items-center justify-center">
        <span className="text-[13px] font-bold text-grey-shade10">
          Progress
        </span>
      </div>
    </div>
  );
};

export default StepCheckHeadUi;
