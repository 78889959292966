import { PAYROLL_ACTION_TYPE, PayrollAction, PayrollState } from "../types/payrollTypes";


export const initialState: PayrollState = {
  currentPage: 1,
  isLoading: false,
  payrolls: [],
  error: null,
  selectedStaffIds: [],
  isTeacherLoading: false,
  teacherError: null,
  teachers: [],
  isCreatingPayroll: false,
};


const payrollReducer = (
  state: PayrollState = initialState,
  action: PayrollAction
): PayrollState => {
  const { type, payload } = action;

  switch (type) {
    case PAYROLL_ACTION_TYPE.UPDATE_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.isLoading ?? state.isLoading,
      };

    case PAYROLL_ACTION_TYPE.REQUEST_SUCCESS:
      return {
        ...state,
        payrolls: payload.payrolls ?? state.payrolls,
      };

    case PAYROLL_ACTION_TYPE.ADD_PAYROLL_ERROR:
      return {
        ...state,
        error: payload.error ?? null,
      };

    case PAYROLL_ACTION_TYPE.SELECT_ALL_STAFF:
      return {
        ...state,
        selectedStaffIds: payload.staffIds ?? [],
      };

    case PAYROLL_ACTION_TYPE.UPDATE_SELECTED_STAFF: {
      const cachedSelectedStaffIds = [...state.selectedStaffIds];
      const staffId = payload.staffId;

      if (staffId) {
        const selectedIndex = cachedSelectedStaffIds.indexOf(staffId);
        if (selectedIndex === -1) {
          cachedSelectedStaffIds.push(staffId); // Add staff to list
        } else {
          cachedSelectedStaffIds.splice(selectedIndex, 1); // Remove staff from list
        }
      }

      return {
        ...state,
        selectedStaffIds: cachedSelectedStaffIds,
      };
    }

    case PAYROLL_ACTION_TYPE.CLEAR_SELECTED_STAFF:
      return {
        ...state,
        selectedStaffIds: [],
      };

    case PAYROLL_ACTION_TYPE.UPDATE_PAYROLL: {
      const indexOfPayroll = state.payrolls.findIndex(
        item => item.id === payload.payroll?.id
      );

      if (indexOfPayroll !== -1 && payload.payroll) {
        const cachedPayrolls = [...state.payrolls];
        cachedPayrolls[indexOfPayroll] = payload.payroll;
        return { ...state, payrolls: cachedPayrolls };
      }
      return state;
    }

    case PAYROLL_ACTION_TYPE.UPDATE_TEACHER_LOADING_STATE:
      return {
        ...state,
        isTeacherLoading: payload.isTeacherLoading ?? state.isTeacherLoading,
      };

    case PAYROLL_ACTION_TYPE.REQUEST_SUCCESS_TEACHER:
      return {
        ...state,
        teachers: payload.teachers ?? state.teachers,
      };

    case PAYROLL_ACTION_TYPE.ADD_TEACHERS_ERROR:
      return {
        ...state,
        teacherError: payload.error ?? null,
      };

    case PAYROLL_ACTION_TYPE.UPDATE_CREATING_PAYROLL_LOADING_STATE:
      return {
        ...state,
        isCreatingPayroll: payload.isCreatingPayroll ?? state.isCreatingPayroll,
      };

    case PAYROLL_ACTION_TYPE.ADD_CREATED_PAYROLL:
      return {
        ...state,
        payrolls: payload.payroll ? [payload.payroll, ...state.payrolls] : state.payrolls,
      };

    default:
      return state;
  }
};

export default payrollReducer;
