import { useEffect, useState } from "react";
import { getSchoolWalletDetails } from "../services/schoolService";

const useGetWallet = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [walletBalance, setWalletBalance] = useState<number>(0.0);


    useEffect(() => {

        const fetchWalletDetails = async () => {

            setIsLoading(true);
            setError(null);

            try {
                const { data: walletResponse } = await getSchoolWalletDetails();

                const { data: walletDto } = walletResponse;

                if (walletResponse.status === false) {
                    throw new Error(walletResponse?.message ?? "Couldn't get wallet details");
                }

                const walletBalance = walletDto.walletBalance;

                setWalletBalance(walletBalance);
            } catch (err) {
                const error = err as Error;
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchWalletDetails();

    }, []);

    return { isLoading, error, walletBalance }
}


export default useGetWallet;