import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AddExamButton } from "../../../shared/components/ui/AddExamButton";
import ExamPane from "../../../shared/components/examinations/ExamPane";
import PageLoading from "../../../shared/components/ui/PageLoading";
import SearchBar from "../../../shared/components/ui/SearchBar";
import GridTileCard from "../../../shared/components/GridTileCard";
import PageSearchIconAndLabel from "../../../shared/components/PageSearchIconAndLabel";
import DismissibleModal from "../../../shared/components/modals/DismissibleModal";
import { AddButtonPlain } from "../../../shared/components/ui/Buttons";

import { accountType } from "../../../constants/accountType";

import { useExamination } from "../../../store/examinationStore/examinationContext";
import { useAuth } from "../../../store/authContext";
import DeleteButton from "../../../assets/svg/delete.svg";
import TickBox from "../../components/ui/TickBox";
import Modal from "../../components/ui/Modal";
import PrerequisiteModalUi from "../../components/modals/PrerequisiteModalUi";

const ExaminationsDashboard = () => {
  const navigate = useNavigate();

  const { user, handleSchoolInitialPageStatuses } = useAuth();
  const { userType, areExamTypesCreated } = user || {};

  const { state, fetchNextData, fetchPreviousData, deleteExam } =
    useExamination();

  const {
    isLoading,
    examinations,
    currentPage,
    totalCount,
    dataPerPage,
    currentPageCount,
  } = state;

  const [showModal, setShowModal] = useState(false);

  const [showAddExamTypeModal, setShowAddExamTypeModal] = useState(false);

  useEffect(() => {
    if (!areExamTypesCreated) {
      setShowModal(true);
    }
  }, [areExamTypesCreated]);

  const [searching, setSearching] = useState(false);

  const [filteredExams, setFilteredExams] = useState([]);

  const filterExams = text => {
    setSearching(true);
    const formattedText = text.toLowerCase();
    let filterExams = examinations.filter(exam =>
      JSON.stringify(exam).toLowerCase().includes(formattedText)
    );
    setFilteredExams(filterExams);
  };
  const [selected, setSelected] = useState([]);

  function handleSelect(value, name) {
    if (value) {
      setSelected([...selected, name]);
    } else {
      setSelected(selected.filter(item => item !== name));
    }
  }

  function selectAll(value) {
    if (value) {
      // if true
      setSelected(examinations); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  }

  return (
    <>
      {/* <DismissibleModal
        showModal={showModal}
        setShowModal={setShowModal}
        height="h-[327px]"
        width="w-[584px]"
        contentStyle="items-center justify-center"
        onCloseButtonClicked={() => setShowModal(false)}
      >
        <p className="text-[22px] font-medium">
          Welcome Admin, kindly create exam types
        </p>
      </DismissibleModal> */}

      <Modal showModal={showModal} onClose={() => setShowModal(false)}>
        <PrerequisiteModalUi
          title="Welcome Admin, kindly create exam types"
          buttonText="Create exam types"
          onClick={() => {
            setShowModal(false);
            setShowAddExamTypeModal(true);
          }}
        />
      </Modal>

      <div>
        <div className="flex w-full flex-row items-center justify-between">
          <div className="mb-[10px] flex items-center space-x-[56px]">
            <h2 className="text-[28px] font-semibold leading-[38px] text-[#272D37]">
              Examination
            </h2>
            {userType === accountType.SCHOOL &&
              !areExamTypesCreated &&
              examinations.length >= 1 && (
                <AddButtonPlain
                  showIcon={false}
                  className="bg-primary"
                  clicked={() => {
                    handleSchoolInitialPageStatuses("areExamTypesCreated");
                    navigate("/", { replace: true });
                  }}
                >
                  NEXT
                </AddButtonPlain>
              )}
          </div>
        </div>

        <div className="flex h-full w-auto flex-col rounded-[18px] bg-[#FAFAFC] pt-12 shadow-md">
          <div className=" mb-5 flex  flex-row-reverse items-center justify-between px-5">
            {/* add button  */}
            <div>
              <div className=" flex flex-row items-center justify-between gap-x-2 ">
                {/* <SearchBar onChange={(text) => filterExams(text)} /> */}

                {/* TODO: Investigate what the "disabled" option is for */}
                <AddExamButton
                  disabled={!areExamTypesCreated && examinations.length === 4}
                  showModal={showAddExamTypeModal}
                  onClose={() => setShowAddExamTypeModal(prev => !prev)}
                />
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="grid h-full w-full place-items-center gap-[10px] pt-[20px]">
              <PageLoading />
            </div>
          )}

          {!isLoading && examinations.length === 0 && (
            <div className="grid h-full w-full place-items-center gap-[10px] pt-[20px]">
              <PageSearchIconAndLabel />
            </div>
          )}

          {!isLoading && examinations.length > 0 && !searching && (
            <>
              <table classes="w-full">
                <thead className="h-full w-full">
                  <tr className="border-b-[1px] border-t-[1px] border-b-[#EAEBF0] border-t-[#EAEBF0]">
                    {/* <th scope="col" className="pl-8">
                      <div className="flex items-center">
                        <TickBox
                          name="all"
                          value={selected.length === examinations.length}
                          updateValue={selectAll}
                        />
                      </div>
                    </th> */}

                    <th className="py-8 px-[24px] text-left font-body  text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                      Exam Name
                    </th>

                    <th className="py-8 text-left font-body  text-sm font-medium leading-[18px] tracking-wide text-[#272D37]">
                      Total Score
                    </th>
                  </tr>
                </thead>

                {examinations.length > 0 &&
                  !searching &&
                  !isLoading &&
                  examinations.map((exam, index) => (
                    <ExamPane
                      key={exam.id || index}
                      exam={exam}
                      name={exam}
                      onDelete={() => deleteExam(exam)}
                      value={selected.includes(exam)}
                      updateValue={handleSelect}
                    />
                  ))}
              </table>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ExaminationsDashboard;
