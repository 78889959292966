import { useState } from "react";

import { BackButton } from "../ui/Buttons";
import Button from "../ui/Button";
import Chip from "./../ui/Chip";
import NormalTextInput from "../ui/NormalTextInput";

import {
  addExamType,
  addTeacher,
} from "../../../features/school/services/schoolService";

import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../../utils/toastHandler";

import { generateRandomId } from "../../../utils/generateId";
import {
  allObjectValuesAreValid,
  removeWhiteSpacesFromObjValues,
} from "../../../utils/helperFunctions";

import { useExamination } from "../../../store/examinationStore/examinationContext";

export default function AddExam({ closeModal }) {
  const { addNewExamTypes } = useExamination();

  // FIXME: Abstract the state and logic using useContext and useReducer

  /// This state object holds the value of the examType and totalScore as inputted
  /// by the user
  const [examTypeAndScore, setExamTypeAndScore] = useState({
    examType: "",
    totalScore: "",
  });

  const [examTypeAndScoreErrors, setExamTypeAndScoreErrors] = useState({
    examType: "",
    totalScore: "",
  });

  const [exams, setExams] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEnterKeyPressed = event => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      handleAppendingNewExamData(event);
    }
  };

  /// This function clears the values of the text fields are successfully
  /// appending exam type data
  const clearTextFields = () => {
    setExamTypeAndScore({ examType: "", totalScore: "" });
  };

  /// This function clears the errors for the text fields if any
  const clearTextFieldsErrors = () => {
    setExamTypeAndScoreErrors({ examType: "", totalScore: "" });
  };

  /// This function is responsible for checking if the exam type to be added is
  /// already present in "exams"
  const isExamTypeAlreadyAdded = data => {
    if (!data) return;

    let isAlreadyPresent = false;

    if (exams.length >= 1) {
      for (const singleExam of exams) {
        const singleExamType = singleExam?.examType;
        const singleExamTotalScore = singleExam?.totalScore;

        if (
          singleExamType.toLowerCase() === data?.examType?.toLowerCase() &&
          singleExamTotalScore === data?.totalScore
        ) {
          isAlreadyPresent = true;
          break;
        }
      }
    }

    return isAlreadyPresent;
  };

  /// This function is responsible for removing exam types in the "exams" array
  const handleRemoveExam = id => {
    const newExams = exams.filter(exam => exam.id !== id);

    setExams(newExams);
  };

  /// This function is responsible for handling adding newly created exam types
  const handleAppendingNewExamData = event => {
    event.preventDefault();
    const typeAndScore = removeWhiteSpacesFromObjValues(examTypeAndScore);

    /// Checking for possible errors
    if (!typeAndScore.examType || !typeAndScore.totalScore) {
      if (!typeAndScore.examType) {
        setExamTypeAndScoreErrors(prevState => {
          return { ...prevState, examType: "Enter exam type" };
        });
      }

      if (!typeAndScore.totalScore) {
        setExamTypeAndScoreErrors(prevState => {
          return { ...prevState, totalScore: "Enter total score" };
        });
      } else if (typeof +typeAndScore.totalScore !== "number") {
        setExamTypeAndScoreErrors(prevState => {
          return { ...prevState, totalScore: "Total score must be a number" };
        });
      }

      return;
    }

    const uniqueId = generateRandomId();

    const examData = {
      id: uniqueId,
      examType: typeAndScore.examType,
      totalScore: +typeAndScore.totalScore,
    };

    /// Checking if exam type has already been added previously
    if (!isExamTypeAlreadyAdded(examData)) {
      setExams(prevState => [...prevState, examData]);
      clearTextFields();
      clearTextFieldsErrors();
    } else {
      showInfoToast("Exam type already added");
    }
  };

  /// This function handle updating the value of "examTypeAndScore"
  const handleExamTypeInputUpdate = (name, value) => {
    const newValue = {
      [name]: value,
    };

    setExamTypeAndScore(prevState => {
      return { ...prevState, ...newValue };
    });
  };

  const handleExamTypesSubmission = async () => {
    setIsSubmitting(true);

    const removeIdsFromExams = [];

    for (const item of exams) {
      let objCopy = {};

      objCopy = { ...item };

      removeIdsFromExams.push(objCopy);
    }

    removeIdsFromExams.map(exam => delete exam.id);

    const payload = { examTypes: [...removeIdsFromExams] };

    try {
      const response = await addExamType(payload);

      const { data } = response;

      if (data.status === true) {
        /// Passing the newly added exam types which is an "array" to the
        /// examinationContext
        addNewExamTypes(data?.examTypeCreate);

        showSuccessToast("Exam types successfully added");

        // Close modal after successfully adding exam type
        closeModal();
        // setExams([]);
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Try adding exam type again");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="h-[500px] w-[400px] px-[10px]">
      <form id="form">
        <h3 className="mt-[8px] text-[22px] font-semibold leading-[33px]">
          Add Exam
        </h3>

        <div className="my-[10px] bg-white p-[5px] ">
          {exams.length > 0 ? (
            <div className="flex max-h-[150px] w-full flex-row flex-wrap gap-[10px] overflow-x-hidden overflow-y-scroll">
              {exams.map((exam, index) => (
                <Chip
                  key={exam.id || index}
                  id={exam.id}
                  name={exam.examType}
                  total={exam.totalScore}
                  onRemove={event => {
                    event.preventDefault();
                    handleRemoveExam(exam.id);
                  }}
                />
              ))}
            </div>
          ) : (
            <p className="text text-center text-[14px] font-normal text-gray-700">
              If you add an exam, it shows up here. If you are done adding an
              exam,{" "}
              <span className="font-bold">
                please press the <span className="text-danger">"ENTER"</span>{" "}
                key.
              </span>
            </p>
          )}
        </div>
        <div className="mt-[20px] grid gap-[20px] bg-white">
          <NormalTextInput
            label={"Exam Type"}
            name="examType"
            value={examTypeAndScore.examType}
            placeholder={"English Language"}
            onChange={value => handleExamTypeInputUpdate("examType", value)}
            onKeyPress={handleEnterKeyPressed}
            errorMessage={
              examTypeAndScoreErrors.examType
                ? examTypeAndScoreErrors.examType
                : null
            }
          />
          <NormalTextInput
            label={"Exam Total Score"}
            type="number"
            name="totalScore"
            value={examTypeAndScore.totalScore}
            placeholder={30}
            onChange={value => handleExamTypeInputUpdate("totalScore", value)}
            onKeyPress={handleEnterKeyPressed}
            errorMessage={
              examTypeAndScoreErrors.totalScore
                ? examTypeAndScoreErrors.totalScore
                : null
            }
          />

          <div className="my-[30px] flex w-full justify-end ">
            <Button
              type="button"
              extraClasses="self-end"
              disabled={exams.length > 0 ? false : true}
              onClick={handleExamTypesSubmission}
              isLoading={isSubmitting}
            >
              Add Exams
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
