import { useEffect, useState } from "react";

import SearchBar from "../../../../shared/components/ui/SearchBar";
import SubjectPane from "../../../../shared/components/ui/SubjectPane";
// import PaginationIndicator from "../../shared/components/ui/PaginationIndicator";
import PageLoading from "../../../../shared/components/ui/PageLoading";
import PageSearchIconAndLabel from "../../../../shared/components/PageSearchIconAndLabel";
import { AddSubjectButton } from "../../../../shared/components/ui/AddSubjectButton";
import { AddButtonPlain } from "../../../../shared/components/ui/Buttons";

import { accountType } from "../../../../constants/accountType";

import { useSubject } from "../../../../store/subjectStore/subjectContext";
import { useAuth } from "../../../../store/authContext";

import Modal from "../../../../shared/components/ui/Modal";
import PrerequisiteModalUi from "../../../../shared/components/modals/PrerequisiteModalUi";

const Subjects = () => {
  const { user, handleSchoolInitialPageStatuses } = useAuth();

  const { userType, areSubjectsCreated } = user || {};

  const { state } = useSubject();

  const { isLoading, error, allSubjects } = state;

  const [showModal, setShowModal] = useState(false);

  const [showAddSubjectModal, setShowAddSubjectModal] = useState(false);

  useEffect(() => {
    if (userType === accountType.SCHOOL && !areSubjectsCreated) {
      setShowModal(true);
    }
  }, [areSubjectsCreated, userType]);

  return (
    <>
      <Modal showModal={showModal} onClose={() => setShowModal(false)}>
        <PrerequisiteModalUi
          title="Welcome Admin, kindly create subjects"
          buttonText="Create subjects"
          onClick={() => {
            setShowModal(false);
            setShowAddSubjectModal(true);
          }}
        />
      </Modal>
      <div className="flex h-full w-auto flex-col overflow-hidden rounded-[22px] bg-[#FAFAFC] p-[50px]">
        <div className="mb-[24px] flex items-center justify-between">
          <div className="flex items-center space-x-[56px]">
            <h2 className="text-[36px] font-semibold leading-[54px] text-black">
              Subjects
            </h2>
            {userType === accountType.SCHOOL &&
              !areSubjectsCreated &&
              allSubjects.length >= 1 && (
                <AddButtonPlain
                  showIcon={false}
                  className="bg-primary"
                  clicked={() =>
                    handleSchoolInitialPageStatuses("areSubjectsCreated")
                  }
                >
                  NEXT
                </AddButtonPlain>
              )}
          </div>

          <AddSubjectButton
            showModal={showAddSubjectModal}
            onClose={() => setShowAddSubjectModal(prev => !prev)}
          />
        </div>

        {isLoading ? (
          <div className="grid h-full w-full place-items-center gap-[10px] pt-[20px]">
            <PageLoading classes="" />
          </div>
        ) : error ? (
          <div className="grid h-full w-full place-items-center gap-[10px] pt-[20px]">
            <PageSearchIconAndLabel label="An error has occurred" />
          </div>
        ) : allSubjects.length === 0 ? (
          <div className="grid h-full w-full place-items-center gap-[10px] pt-[20px]">
            <PageSearchIconAndLabel label="No subject found" />
          </div>
        ) : (
          <div className="grid grid-cols-4 grid-rows-1 place-items-center gap-[10px] pt-[20px]">
            {allSubjects.map((subject, index) => (
              <SubjectPane subject={subject} key={index} />
            ))}
          </div>
        )}

        {/* <PaginationIndicator
          classes="self-end mt-[12px]"
          currentPageNumber={null}
          itemPerPage={null}
          itemCount={null}
          totalCount={null}
          onForwardArrowClick={null}
          onBackArrowClick={null}
        /> */}
      </div>
    </>
  );
};

export default Subjects;
