import CheckMark from "../../../components/icons/CheckMark.js";

interface SuccessModalProps {
  message?: string;
}

const SuccessModal = ({
  message = "Your payment was successful",
}: SuccessModalProps) => {
  return (
    <div className="flex h-auto w-[576px] flex-col items-center justify-center">
      <p className="mb-[31px] text-[24px] font-semibold text-black-shade1">
        Success
      </p>

      <div className="mb-[39px] rounded-full border-[4px] border-success p-[8px]">
        <CheckMark className="text-[32px] text-success" />
      </div>

      <p className="text-[20px] font-medium tracking-[-0.02em]">{message}</p>
    </div>
  );
};

export default SuccessModal;
