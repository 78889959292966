const Button = ({
  buttonColor = "bg-blue",
  type = "button",
  width = "100%",
  height = "h-[48px]",
  padding = "",
  extraClasses,
  disabled = false,
  isLoading = false,
  onClick,
  children,
}) => {
  const loadingClasses =
    "flex justify-center items-center disabled:cursor-not-allowed disabled:opacity-50";

  return (
    <button
      onClick={onClick}
      className={`${buttonColor} ${extraClasses} ${width} ${height} ${padding} w-full rounded-[8px] text-white ${
        (isLoading || disabled) && loadingClasses
      }`}
      type={type}
      disabled={isLoading ? true : disabled}
    >
      {isLoading ? (
        <svg
          className="mr-3 h-6 w-6 animate-spin rounded-full border-l-4 border-white"
          viewBox="0 0 24 24"
        ></svg>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
