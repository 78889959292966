import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { usePayroll } from "../store/payrollContext";
import { debugPrint } from "../../../../utils/debugPrint";
import { createPayrollSchema } from "../../utils/createPayrollSchema";
import SelectFormik from "../../../../shared/components/ui/SelectFormik";
import { getYearRange } from "../../../../utils/getYearRange";
import { getMonths } from "../../../../utils/getMonths";
import Button from "../../../../shared/components/ui/Button";

const CreatePayrollModal = () => {
  const navigate = useNavigate();

  const { state } = usePayroll();

  const initialValues = {
    year: "",
    month: "",
    role: "",
  };

  const formHandler = values => {
    debugPrint("CreatePayrollModal - formHandler -- state -> BEFORE =", state);
    navigate("create-payroll", { state: { createPayrollObj: values } });
    debugPrint("CreatePayrollModal - formHandler -- state -> AFTER =", state);
  };

  return (
    <div className="flex w-[584px] flex-col">
      <h1 className="text-20px mb-[16px] font-semibold">Create Payroll</h1>

      <Formik
        initialValues={initialValues}
        validationSchema={createPayrollSchema}
        onSubmit={formHandler}
      >
        {({ handleChange, isSubmitting }) => (
          <Form className="flex flex-col">
            <SelectFormik
              showDefaultOption={false}
              className="flex flex-1"
              hiddenLabel={"Year"}
              label="Year"
              name="year"
              options={getYearRange()}
            />
            <SelectFormik
              showDefaultOption={false}
              className="flex flex-1"
              hiddenLabel={"Month"}
              label="Month"
              name="month"
              options={getMonths()}
            />
            <SelectFormik
              showDefaultOption={false}
              className="flex flex-1"
              hiddenLabel={"Role"}
              label="Role"
              name="role"
              options={["Teacher"]}
            />
            <Button
              type="submit"
              extraClasses="self-center mt-[25px]"
              isLoading={isSubmitting}
            >
              <div className="flex items-center justify-center space-x-2">
                <span className=" ">Next</span>
              </div>
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreatePayrollModal;
