import { PropTypes } from "prop-types";

import { debugPrint } from "../../../../utils/debugPrint";
import TickBox from "../../../../shared/components/ui/TickBox";

const PayrollTableHeader = ({
  label1,
  label2,
  label3,
  label4,
  label5,
  label6,
  showCheckBox = false,
  checked = false,
  onChange = () => debugPrint("PayrollTableHeader - checkbox clicked!!!"),
}) => {
  return (
    <thead className="">
      <tr className="border-b-[1px] border-b-[#EAEBF0]">
        {showCheckBox && (
          <th
            scope="col"
            className="px-6 py-8 font-body text-sm font-medium leading-[18px] text-[#5F6D7E]"
          >
            <TickBox value={checked} updateValue={onChange} />
          </th>
        )}
        <th
          scope="col"
          className="w-[320px] px-6 py-8 font-body text-sm font-medium leading-[18px] text-[#5F6D7E]"
        >
          {label1}
        </th>
        <th
          scope="col"
          className="px-6 py-8 font-body text-sm font-medium leading-[18px] text-[#5F6D7E]"
        >
          {label2}
        </th>
        <th
          scope="col"
          className="px-6 py-8 text-center font-body text-sm font-medium leading-[18px] text-[#5F6D7E]"
        >
          {label3}
        </th>
        {/* <th
          scope="col"
          className="px-6 py-8 text-center font-body text-sm font-medium leading-[18px] text-[#5F6D7E]"
        >
          {label4}
        </th> */}
        <th
          scope="col"
          className="px-6 py-8 font-body text-sm font-medium leading-[18px] text-[#5F6D7E]"
        >
          {label5}
        </th>
        {label6 && (
          <th
            scope="col"
            className="px-6 py-8 text-center font-body text-sm font-medium leading-[18px] text-[#5F6D7E]"
          >
            {label6}
          </th>
        )}
      </tr>
    </thead>
  );
};

PayrollTableHeader.propTypes = {
  showCheckBox: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  label3: PropTypes.string.isRequired,
  label4: PropTypes.string.isRequired,
  label5: PropTypes.string.isRequired,
  label6: PropTypes.string.isRequired,
};

export default PayrollTableHeader;
