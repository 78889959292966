import TickBox from "../../../../shared/components/ui/TickBox";
import { debugPrint } from "../../../../utils/debugPrint";
import {
  getPayrollStatusDarkColor,
  getPayrollStatusLightColor,
  getPayrollStatusNew,
} from "../utils/payrollUtil";

interface PayrollCardProps {
  data1: string;
  data2: string;
  data3: string;
  data4: string;
  data5: string;
  actionIcon: React.ReactNode;
  onActionIconClicked: () => void;
  showCheckBox?: boolean;
  showCard?: boolean;
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

const PayrollCard = ({
  data1,
  data2,
  data3,
  data4,
  data5,
  actionIcon,
  onActionIconClicked,
  showCheckBox = false,
  showCard = false,
  checked = false,
  onChange = () => {},
}: PayrollCardProps) => {
  debugPrint("PayrollCard - data5 --", data5);
  return (
    <tbody>
      <tr className="border-b border-b-[#EAEBF0]">
        {showCheckBox && (
          <td className="px-6 py-7 font-body text-sm font-medium text-[#5F6D7E]">
            <TickBox value={checked} updateValue={onChange} />
          </td>
        )}
        <td className="px-6 py-7 font-body text-sm font-medium text-[#5F6D7E]">
          {data1}
        </td>
        <td className="flex items-center whitespace-nowrap px-6 py-7 font-medium">
          {data2}
        </td>

        <td className="px-6 py-7 text-center font-body text-sm font-medium text-[#5F6D7E]">
          {data3}
        </td>

        {/* <td className="px-6 py-7 text-center font-body text-sm font-medium text-[#5F6D7E]">
          {data4}
        </td> */}

        <td className="px-6 py-7 text-[14px] font-medium text-grey-shade10">
          <div
            className="inline-flex h-[22px] items-center justify-center rounded-[5px] p-[8px] text-[13px]"
            style={{
              backgroundColor: showCard
                ? getPayrollStatusLightColor(data5)
                : "",
              color: showCard ? getPayrollStatusDarkColor(data5) : "",
            }}
          >
            <span
              className="mr-[6px] h-[6px] w-[6px] rounded-[5px]"
              style={{
                backgroundColor: showCard
                  ? getPayrollStatusDarkColor(data5)
                  : "",
              }}
            ></span>
            {/* {data5} */}
            {showCard ? getPayrollStatusNew(data5) : data5}
          </div>
        </td>

        {actionIcon && (
          <td
            className="relative flex items-center justify-center px-6 py-7 text-center font-medium"
            onClick={onActionIconClicked}
          >
            <span className="cursor-pointer">{actionIcon}</span>
          </td>
        )}
      </tr>
    </tbody>
  );
};

export default PayrollCard;
