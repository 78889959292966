import * as React from "react";

const SvgDashboardIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.25 1.027h5.5c2.567 0 3.85 0 4.83.5a4.584 4.584 0 0 1 2.004 2.002c.5.98.5 2.264.5 4.831v5.5c0 2.567 0 3.85-.5 4.83a4.584 4.584 0 0 1-2.003 2.004c-.98.5-2.264.5-4.83.5h-5.5m0-20.167c-2.568 0-3.851 0-4.832.5a4.583 4.583 0 0 0-2.003 2.002c-.5.98-.5 2.264-.5 4.831v5.5c0 2.567 0 3.85.5 4.83a4.583 4.583 0 0 0 2.003 2.004c.98.5 2.264.5 4.831.5m0-20.167v20.166m0-10.083H22"
      stroke="#437EF7"
      strokeWidth={1.67}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgDashboardIcon;
