import { WarningOutlineCircle } from "../../../../components/icons";

interface PayNowModalUiProps {
  message: string;
  onClick: () => void;
}

const PayNowModalUi = ({ message, onClick }: PayNowModalUiProps) => {
  return (
    <div className="flex h-auto w-[584px] flex-col">
      <p className="mb-[22px] text-[20px] font-semibold text-black-shade1">
        Pay now
      </p>

      <WarningOutlineCircle className="mb-[26px] self-center text-[48px]" />

      <p className="mb-[26px] self-center text-[16px] font-medium text-black-shade15">
        {message}
      </p>

      <button
        onClick={onClick}
        className="rounded-[8px] bg-primary py-[10px] text-[14px] font-semibold text-white"
      >
        Proceed
      </button>
    </div>
  );
};

export default PayNowModalUi;
