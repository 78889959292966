import { PropTypes } from "prop-types";
import { CheckMark } from "../../components/icons";

const StepperReg = ({
  label,
  isActive = false,
  showVertStep = true,
  isCompleted = false,
}) => {
  const circularStepperStyle = () => {
    if (isCompleted) {
      return "bg-white";
    }

    if (isActive) {
      return "bg-white bg-opacity-50";
    }

    return "opacity-50";
  };

  return (
    <div className="flex gap-[18px]">
      <div className="flex flex-col items-center justify-center gap-[6px]">
        {/* Circular stepper component */}
        <div
          className={`relative h-[24px] w-[24px] rounded-full border-2 border-white ${circularStepperStyle()}`}
        >
          {isCompleted && (
            <CheckMark className="absolute top-[2px] bottom-0 left-[2px] right-0 text-primary" />
          )}
        </div>
        {/* Vertical stepper */}
        {showVertStep && (
          <div
            className={`h-[26px] w-[2px] bg-white ${
              !isActive && !isCompleted && "opacity-50"
            }`}
          ></div>
        )}
      </div>

      <p
        className={`text-[18px] font-semibold text-white ${
          !isActive && !isCompleted && "text-opacity-50"
        }`}
      >
        {label}
      </p>
    </div>
  );
};

StepperReg.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  showVertStep: PropTypes.bool,
  isCompleted: PropTypes.bool,
};

export default StepperReg;
