import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import Layout from "../shared/components/layouts/Layout";

import { accountType } from "../constants/accountType";
import { routesNotForStudent, routesNotForTeacher } from "../config/routes";

import { useAuth } from "../store/authContext";

const ProtectedRoute = () => {
  let location = useLocation();
  const navigate = useNavigate();

  const { user } = useAuth();

  const {
    userType,
    areSubjectsCreated,
    areSubjectsAssignedToClasses,
    areExamTypesCreated,
    // areResultCustomsCommentCreated,
  } = user || {};

  const navigatorOrchestrator = () => {
    if (userType === accountType.TEACHER) {
      if (routesNotForTeacher.includes(location.pathname)) {
        navigate("/");
      }

      return;
    }

    if (userType === accountType.STUDENT) {
      if (routesNotForStudent.includes(location.pathname)) {
        navigate("/");
      }

      return;
    }

    if (
      areSubjectsCreated &&
      areSubjectsAssignedToClasses &&
      areExamTypesCreated
    ) {
      if (location.pathname === "/prerequisite") {
        navigate("/");
      }

      return;
    }

    // It's a school account
    let redirectPath = null;

    if (!areSubjectsCreated) {
      redirectPath =
        location.pathname === "/academic/subjects"
          ? "/academic/subjects"
          : "/prerequisite";
    } else if (!areSubjectsAssignedToClasses) {
      redirectPath =
        location.pathname === "/academic/class"
          ? "/academic/class"
          : "/prerequisite";
    } else if (!areExamTypesCreated) {
      redirectPath =
        location.pathname === "/assessments/exam-types"
          ? "/assessments/exam-types"
          : "/prerequisite";
    }

    if (redirectPath) {
      navigate(redirectPath, { replace: true });
    }
  };

  useEffect(() => {
    navigatorOrchestrator();
  }, [
    userType,
    areSubjectsCreated,
    areSubjectsAssignedToClasses,
    areExamTypesCreated,
  ]);

  return user ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" replace={true} state={{ from: location }} />
  );
};

export default ProtectedRoute;
