import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePayroll } from "../store/payrollContext";
import {
  getCreatePayrollStatus,
  getShouldShowPayrollButton,
  payrollStatus,
} from "../utils/payrollUtil";
import {
  makePayrollPayment,
  retryPayrollPayment,
} from "../../services/schoolService";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/toastHandler";
import { Delete } from "../../../../shared/components/icons";
import SearchField from "../../../../shared/components/ui/SearchField";
import { AddButtonPlain } from "../../../../shared/components/ui/Buttons";
import PayrollTableHeader from "../components/PayrollTableHeader";
import PayrollCard from "../components/PayrollCard";
import { extractFirstWordToUpperCase } from "../../../../utils/helperFunctions";
import { debugPrint } from '../../../../utils/debugPrint';

const PayrollItem = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const payroll = location?.state?.payroll;

  const payItems = payroll?.payItem;

  const {
    state,
    clearSelectedStaff,
    updateSelectedStaff,
    selectAllStaffIds,
    updatePayroll,
  } = usePayroll();

  const { selectedStaffIds } = state;

  useEffect(() => {
    clearSelectedStaff();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const handleSelectAll = () => {
    if (payItems.length === selectedStaffIds.length) {
      // Deselect all
      selectAllStaffIds([]);

      return;
    }
    const staffIds = payItems.map(item => item.id);

    // Select all
    selectAllStaffIds(staffIds);
  };

  const handleMakePayment = async () => {

    setIsLoading(true);
    try {
      let payload;

      if (payroll?.PayrollProcessingState === payrollStatus.FAILED) {
        payload = {
          // schoolAuthId: payroll?.schoolAuthId ?? "",
          payrollId: payroll?.id ?? "",
        };
      } else {
        payload = {
          payrollId: payroll.id,
          paymentType: "payNow",
        };
      }

      const { data } =
        payroll?.PayrollProcessingState === payrollStatus.FAILED
          ? await retryPayrollPayment(payload)
          : await makePayrollPayment(payload);

      const { status, message } = data;

      if (status === true) {
        showSuccessToast(message ?? "Success");

        // Change the payroll "PayrollProcessingState" to "completed"
        const updatedPayroll = {
          ...payroll,
          PayrollProcessingState: payrollStatus.COMPLETED,
        };

        // Updating the payroll in state
        updatePayroll(updatedPayroll);

        navigate(-1);
      } else {
        throw new Error(
          message ?? "Failed to trigger payment"
        );
      }
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mb-[24px] flex flex-col overflow-y-auto">
      <h1 className="mb-[24px] text-[28px] font-semibold text-[#272D37]">
        Payroll Manager
      </h1>

      <div className="flex h-[600px] w-full flex-col rounded-[10px] border-[1px] border-solid border-[#EAEBF0] px-[16px] py-[24px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[16px]">
            <Delete />
            <SearchField />
          </div>
          {getShouldShowPayrollButton(payroll.PayrollProcessingState) && (
            <div className="mb-[16px] flex items-center justify-between gap-[12px]">
              <AddButtonPlain
                showIcon={false}
                isLoading={isLoading}
                className={`h-[40px] bg-[#437EF7] px-[40px]`}
                clicked={handleMakePayment}
              >
                {/* Make payment */}
                {getCreatePayrollStatus(payroll?.PayrollProcessingState)}
              </AddButtonPlain>
            </div>
          )}
        </div>

        <div className="relative h-full w-full overflow-x-auto scroll-smooth rounded-b-[10px]">
          <table className="w-full text-left text-sm rtl:text-right">
            <PayrollTableHeader
              label1="ID"
              label2="All Staffs"
              label3="Role"
              label4="Month"
              label5="Salary"
              label6={
                getShouldShowPayrollButton(payroll.PayrollProcessingState) &&
                "Action"
              }
              showCheckBox={getShouldShowPayrollButton(
                payroll.PayrollProcessingState
              )}
              checked={payItems.length === selectedStaffIds.length}
              onChange={handleSelectAll}
            />
            {payItems.map((item, index) => (
              <PayrollCard
                key={item?.id ?? index}
                showCheckBox={getShouldShowPayrollButton(
                  payroll.PayrollProcessingState
                )}
                checked={selectedStaffIds.includes(item.id)}
                data1={item?.id ?? "-- --"}
                data2={item?.accountName ?? "-- --"}
                data3={extractFirstWordToUpperCase(payroll?.role) ?? "-- --"}
                data4={extractFirstWordToUpperCase(payroll?.month) ?? "-- --"}
                data5={item?.salary ?? "-- --"}
                actionIcon={
                  getShouldShowPayrollButton(
                    payroll.PayrollProcessingState
                  ) && <Delete />
                }
                onActionIconClicked={() =>
                  console.log("PayrollItem - onActionIconClicked -- CLICKED!!!")
                }
                onChange={() => updateSelectedStaff(item?.id)}
              />
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default PayrollItem;
