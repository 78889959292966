import { useToggleBodyScroll } from "../../../hooks/useToggleBodyScroll";

/**
 * @typedef {Object} Props
 * @property {boolean} showModal - Determines if the modal should be shown
 * @property {function} onClose - Function to close a visible modal
 * @property {boolean} [isDismissible] - Default to "false" and determines if clicking out the modal should dismiss it
 * @property {React.Node} children - Children component
 * @property {boolean} [showCloseButton] - Determines if a close button should be shown
 */

/**
 * @description - UI component for displaying a modal
 * @param {Props} props - The properties for the Modal component
 * @return {React.JSX.Element} - The rendered modal component
 */
const Modal = ({
  showModal,
  onClose,
  isDismissible = false,
  children,
  showCloseButton = true,
}) => {
  useToggleBodyScroll(showModal);

  // Backdrop
  return (
    <div
      onClick={isDismissible ? onClose : () => {}}
      className={`fixed inset-0 z-50 flex items-center justify-center overflow-y-auto ${
        showModal ? "visible bg-black bg-opacity-20" : "invisible"
      }`}
    >
      {/* Modal */}
      <div
        onClick={e => e.stopPropagation()}
        className={`rounded-xl bg-white p-6 shadow transition-all ${
          showModal ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >

        <button
          onClick={onClose}
          className="absolute top-4 right-2 h-[44px] w-[44px] rounded-lg bg-[#F4F9FD] p-1 text-gray-400 hover:text-gray-600"
        >
          &#x2715;
        </button>
        <div className="no-scrollbar overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
