import PrimaryButtonUi from "../buttons/PrimaryButtonUi";

interface PrerequisiteModalUiProps {
  title: string;
  buttonText: string;
  onClick: () => void;
}

const PrerequisiteModalUi = ({
  title,
  buttonText,
  onClick,
}: PrerequisiteModalUiProps) => {
  return (
    <div className="my-[48px] flex h-auto w-[584px] flex-col items-center justify-center space-y-[56px]">
      <span className="text-[20px] font-semibold text-black-shade10">
        {title}
      </span>

      <PrimaryButtonUi title={buttonText} onClick={onClick} />
    </div>
  );
};

export default PrerequisiteModalUi;
