import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../ui/Button";
import { Selector } from "./../MultiCheckBox";

import { useClassSubjectContext } from "../../../store/classStore/classContext";

import {
  fetchClassSubject,
  getAllSchoolSubjects,
  updateClassSubjects,
} from "../../../features/school/services/schoolService";
import { getTeacherAllSubjects } from "../../../features/teacher/services/teacherService";

import { showErrorToast, showSuccessToast } from "../../../utils/toastHandler";

import { accountType } from "../../../constants/accountType";

import { useAuth } from "../../../store/authContext";
import { debugPrint } from "../../../utils/debugPrint";

const classess = [
  { value: "jss1", label: "JSS1" },
  { value: "jss2", label: "JSS2" },
  { value: "jss3", label: "JSS3" },
  { value: "sss1", label: "SSS1" },
  { value: "sss2", label: "SSS2" },
  { value: "sss3", label: "SSS3" },
];

export default function AddSubjectToClass({ selectedClassProp, closeModal }) {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { userType, areSubjectsAssignedToClasses } = user || {};

  // const { subjects, isLoading, error } = useGetSchoolSubjects();

  const [subjects, setSubjects] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { updateNewlyAddedClassSubjects, handleInitialSubjectAssign } =
    useClassSubjectContext();

  const [subjectState, setSubjectState] = useState({
    selectedClass: null, // Selected class by the user from the dropdown options
    isSubjectLoading: false,
    isSubmitting: false, // Used when submitting data the database
    error: null,
    subjectsOfferedByClass: [], // This will contain the subject(s) currently offered by the class
    subjectsToDisplay: [], // This will contain the subject(s) not currently offered by the class
  });

  const [subjectsToAddToClass, setSubjectsToAddToClass] = useState([]);

  const _handleSubjectFetch = async () => {
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolSubjectData } = await getAllSchoolSubjects();
        return schoolSubjectData;

      case accountType.TEACHER:
        const { data: teacherSubjectData } = await getTeacherAllSubjects();
        return teacherSubjectData;
      default:
        break;
    }
  };

  const fetchSchoolSubjects = async () => {
    setIsLoading(true);
    try {
      const response = await _handleSubjectFetch();

      if (response.status === true) {
        const { data } = response;

        const listOfSubjects = data[0]?.listOfSubjects;

        setSubjects(listOfSubjects);
        return listOfSubjects;
      } else {
        throw new Error(response?.message ?? "Error retrieving subjects");
      }
    } catch (err) {
      // setError(err?.message ?? "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubjectSelection = subjectArray => {
    debugPrint(
      "AddSubjectToClass - handleSubjectSelection -- subjectArray ->",
      subjectArray
    );
    // const cachedSubjects = [...subjectsToAddToClass];

    // const indexOfSubject = cachedSubjects.findIndex(
    //   (item) => item.value === subjectArray
    // );

    // if (indexOfSubject === -1) {
    //   const obj = {
    //     value: subjectArray,
    //     label: subjectArray,
    //   };
    //   cachedSubjects.push(obj);
    // } else {
    //   cachedSubjects.splice(indexOfSubject, 1);
    // }

    const cachedSubjects = [...subjectArray];

    setSubjectsToAddToClass(cachedSubjects);
  };

  // TODO: Document function
  const handleSetClass = async chosenClass => {
    /// If the same class is re-selected don't run the rest of the code
    if (
      subjectState.selectedClass !== null &&
      subjectState.selectedClass.value === chosenClass
    ) {
      return;
    }

    const obj = {
      value: chosenClass,
      label: chosenClass.toUpperCase(),
    };

    setSubjectState(prevState => {
      return {
        ...prevState,
        isSubjectLoading: true,
        error: null,
        selectedClass: obj,
        subjectsToDisplay: [],
      };
    });

    try {
      let fetchedSubjects = [];
      if (subjects == null || subjects === undefined) {
        const data = await fetchSchoolSubjects();

        fetchedSubjects = [...data];
      }

      const { data } = await fetchClassSubject(chosenClass);

      const { status, message, data: innerData } = data;

      if (status === false) {
        throw new Error(message ?? "Something went wrong");
      }

      const currentSubjectsOfferedByClass = [...innerData];

      // if (subjects == null || subjects === undefined) {
      //   debugPrint("NULL");
      //   await fetchSchoolSubjects();
      // }

      const subjectsToUse = subjects == null ? fetchedSubjects : subjects;

      if (subjectsToUse?.length === currentSubjectsOfferedByClass?.length) {
        setSubjectState(prevState => {
          return {
            ...prevState,
            subjectsOfferedByClass: [...currentSubjectsOfferedByClass],
            subjectsToDisplay: [],
          };
        });
        return;
      }

      const subjectsToDisplay = [];

      let pointer = 0;

      while (pointer < subjectsToUse?.length) {
        if (!currentSubjectsOfferedByClass?.includes(subjectsToUse[pointer])) {
          const obj = {
            value: subjectsToUse[pointer],
            label: subjectsToUse[pointer],
          };
          subjectsToDisplay.push(obj);
        }
        pointer++;
      }

      setSubjectState(prevState => {
        return {
          ...prevState,
          subjectsOfferedByClass: [...currentSubjectsOfferedByClass],
          subjectsToDisplay: [...subjectsToDisplay],
        };
      });
    } catch (err) {
      setSubjectState(prevState => {
        return {
          ...prevState,
          error: err?.message ?? "Something went wrong",
        };
      });
    } finally {
      setSubjectState(prevState => {
        return {
          ...prevState,
          isSubjectLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    if (selectedClassProp != null) {
      handleSetClass(selectedClassProp);
    }
  }, [selectedClassProp]);

  const handleSubmitSubject = async () => {
    setSubjectState(prevState => {
      return {
        ...prevState,
        isSubmitting: true,
      };
    });
    const arrayOfSubjects = [];

    for (let index = 0; index < subjectsToAddToClass.length; index++) {
      arrayOfSubjects.push(subjectsToAddToClass[index].value);
    }

    const payload = {
      [subjectState.selectedClass.value]: arrayOfSubjects,
    };

    try {
      const { data } = await updateClassSubjects(payload);

      if (data?.status === true) {
        showSuccessToast(data?.message ?? "Success");

        /// This function call will set the [isInitialSubjectAssigned] in the
        /// [useClassSubjectContext()] to "true" which will indicate that this
        /// is the first time the school is assigning subjects to a class
        if (!areSubjectsAssignedToClasses) {
          handleInitialSubjectAssign();
        }

        if (selectedClassProp === subjectState.selectedClass.value) {
          // Passing the newly added subjects back to the classContext store to
          // update the UI
          updateNewlyAddedClassSubjects(arrayOfSubjects);
        }

        /// Closing the add subject modal
        closeModal();

        // Resetting modal form
        setSubjectState(prevState => {
          return {
            ...prevState,
            selectedClass: null,
            subjectsOfferedByClass: [],
            subjectsToDisplay: [],
          };
        });
        return;
      }

      throw new Error(data?.message ?? "Something went wrong");
    } catch (err) {
      showErrorToast(err?.message ?? "Something went wrong");
    } finally {
      setSubjectState(prevState => {
        return { ...prevState, isSubmitting: false };
      });
    }
  };

  /// This method is responsible for navigating a user to the "Subjects" page
  /// when no subject has been added
  const handleSubjectNavigation = () => {
    navigate("/academic/subjects");
  };

  return (
    <div className="h-[400px] w-[576px]">
      <form id="form" className="flex h-full flex-col">
        <h3 className="mt-[8px] mb-[18px] text-[22px] font-semibold leading-[33px]">
          Add Subjects
        </h3>
        <div className="my-[10px] flex flex-col bg-white p-[5px]">
          <Selector
            extraClasses="mb-[10px]"
            label="Class(es)"
            // options={classess}
            // options={isLoading ? [] : classess}
            options={
              subjectState.selectedClass == null
                ? isLoading
                  ? []
                  : classess
                : classess
            }
            value={subjectState.selectedClass}
            // value={
            //   selectedClassProp == null
            //     ? subjectState.selectedClass
            //     : defaultSelectedClassPropObj
            // }
            placeholder="Select class(es)"
            handleSelection={obj => handleSetClass(obj.value)}
            isMulti={false}
            isSearchable={false}
            showValues={true}
            closeMenuOnSelect={true}
            noOptionsMessage="Loading..."
          />

          <Selector
            label="Subject(s)"
            value={subjectsToAddToClass}
            style={{ marginTop: 50 }}
            placeholder={
              subjectState.isSubjectLoading ? "Loading..." : "Select subject(s)"
            }
            noOptionsMessage={
              subjectState.selectedClass === null
                ? "Please select a class"
                : "No subjects found"
            }
            options={
              !subjectState.isSubjectLoading && subjectState.subjectsToDisplay
            }
            handleSelection={value => handleSubjectSelection(value)}
            isMulti={true}
            isSearchable={true}
            showValues={false}
            closeMenuOnSelect={false}
            isLoading={subjectState.isSubjectLoading}
            shouldDisable={subjectState.isSubjectLoading}
          />

          {subjectState.selectedClass !== null &&
            !subjectState.isSubjectLoading &&
            subjectState.subjectsToDisplay?.length === 0 && (
              <span
                className="mt-[48px] cursor-pointer text-[16px] text-accent"
                onClick={() => handleSubjectNavigation()}
              >
                click here to create subject
              </span>
            )}
        </div>

        <div className="flex h-full items-end justify-end">
          <Button
            type="button"
            disabled={subjectsToAddToClass.length !== 0 ? false : true}
            onClick={handleSubmitSubject}
            isLoading={subjectState.isSubmitting}
          >
            {subjectsToAddToClass.length > 0
              ? `${
                  subjectState.selectedClass !== null
                    ? `Add Subjects to ${subjectState.selectedClass.label}`
                    : "Add Subjects"
                }`
              : `${
                  subjectState.selectedClass !== null
                    ? `Add Subject to ${subjectState.selectedClass.label}`
                    : "Add Subject"
                }`}
          </Button>
        </div>
      </form>
    </div>
  );
}
