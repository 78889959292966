import styles from "./LoadingShimmer.module.css";

interface LoadingShimmerProps {
  height?: string;
}

const LoadingShimmer = ({ height = "h-[166px]" }: LoadingShimmerProps) => (
  <div className={`relative ${height}`}>
    <div
      className={`absolute top-0 left-0 h-full w-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 ${styles.animateShimmer}`}
    ></div>
  </div>
);

export default LoadingShimmer;
