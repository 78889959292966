import * as React from "react";

const SvgArrowRightLight = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.326.333a.478.478 0 0 0 0 .668L5.199 6 .326 10.998a.478.478 0 0 0 .15.77.456.456 0 0 0 .502-.102L6.16 6.349a.5.5 0 0 0 0-.698L.978.334a.455.455 0 0 0-.652 0V.333Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgArrowRightLight;
