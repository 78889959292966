import { NavLink } from "react-router-dom";

import { ArrowRightLight } from "../../../../components/icons";
import CheckBoxOutline from "../../../../shared/components/ui/checkboxes/CheckBoxOutline";

interface StepCheckTileUIProps {
  step: string;
  actionTitle: string;
  actionRoute: string;
  isChecked: boolean;
}

const StepCheckTileUi = ({
  step,
  actionTitle,
  actionRoute,
  isChecked,
}: StepCheckTileUIProps) => {
  return (
    <div className="grid grid-cols-12 border-b-[1px] border-grey-shade15 py-[22px]">
      {/* Steps */}
      <div className="col-span-4 ml-[24px]">
        <span className="text-[14px] font-medium text-black-shade10">
          {step}
        </span>
      </div>

      {/* Action */}
      <NavLink to={actionRoute} className="col-span-6">
        <div className="flex items-center">
          <span className="mr-[16px] cursor-pointer text-[14px] font-medium text-grey-shade10">
            {actionTitle}
          </span>
          <span className="cursor-pointer text-[12px] text-black">
            <ArrowRightLight />
          </span>
        </div>
      </NavLink>

      {/* Progress */}
      <div className="col-span-2">
        <span className="flex items-center justify-center font-bold text-grey-shade10">
          {/* <CheckBox onChange={() => {}} checked={true} /> */}
          <CheckBoxOutline isChecked={isChecked} onChange={() => {}} />
        </span>
      </div>
    </div>
  );
};

export default StepCheckTileUi;
