import * as Yup from "yup";
import { phoneRegExp } from "../phoneRegExp";
import {
  IMAGE_SIZE_LIMIT,
  SUPPORT_PDF,
  SUPPORTED_IMAGE_FORMAT,
} from "../supportedImageTypes";

export const teacherValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  middleName: Yup.string(),
  teacherEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  address: Yup.string()
    .required("Address is required")
    .trim("Testing the trimming feature")
    .min(5, "Address must be more than 5 characters"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  countryCode: Yup.string(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Enter valid number")
    .required("Phone number is required"),
  profileImageUrl: Yup.mixed()
    .required("Image is required")
    .test(
      "fileSize",
      "File too large",
      value => value && value.size <= IMAGE_SIZE_LIMIT
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value && SUPPORTED_IMAGE_FORMAT.includes(value.type)
    ),
  documents: Yup.mixed()
    .test(
      "fileSize",
      "File too large",
      value => !value || (value && value.size <= IMAGE_SIZE_LIMIT)
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      value =>
        !value ||
        (value &&
          [...SUPPORTED_IMAGE_FORMAT, ...SUPPORT_PDF].includes(value?.type))
    ),

  classes: Yup.array().min(1, "Select a class"),
  subjects: Yup.array().min(1, "Select a subject"),
  formerEmployerName: Yup.string(),
  formerJobTitle: Yup.string(),
  yearsOfExperience: Yup.number("Enter a valid number"),
  dateOfBirth: Yup.string().required("Date of birth is required"),
  LGA: Yup.string().required("L.G.A is required"),
  salary: Yup.number("Enter a valid salary").typeError("Enter valid number"),
  salaryDuration: Yup.string().trim(),
  accountName: Yup.string("Enter a valid account name").trim(),
  accountNumber: Yup.string("Enter a valid account number")
    .trim()
    .matches(/^\d+$/, "Account number should only contain digits")
    .min(10, "Account number should be 10 digits")
    .max(10, "Account number cannot be more than 10 digits"),
  bank: Yup.string().trim(),
  bankCode: Yup.string().trim(), // This is gotten from the bank and is not required
});
