interface CheckBoxOutlineProps {
  isChecked: boolean;
  onChange: () => void;
}

const CheckBoxOutline = ({ isChecked, onChange }: CheckBoxOutlineProps) => {
  return (
    <div className="h-[20px] w-[20px]">
      <input
        className="rounded-[6px] border-blue-shade10 accent-gray-500"
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
    </div>
  );
};

export default CheckBoxOutline;
