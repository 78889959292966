import { Nullable } from "../../../../types/nullable";
import { PayrollDto } from "../../../../types/schoolService/schoolServiceTypes";

export interface PayrollState {
    currentPage: number;
    isLoading: boolean;
    payrolls: PayrollDto[]; // You might want to define a Payroll interface for this
    error: Nullable<string>;
    selectedStaffIds: string[];
    isTeacherLoading: boolean;
    teacherError: Nullable<string>;
    teachers: any[]; // You might want to define a Teacher interface for this
    isCreatingPayroll: boolean;
}


// Action interface
export interface PayrollAction {
    type: PAYROLL_ACTION_TYPE;
    payload: {
        isLoading?: boolean;
        payrolls?: PayrollDto[];
        error?: string;
        staffIds?: string[];
        staffId?: string;
        payroll?: PayrollDto;
        isTeacherLoading?: boolean;
        teacherError?: Nullable<string>;
        teachers?: any[];
        isCreatingPayroll?: boolean;
    };
}


export const enum PAYROLL_ACTION_TYPE {
    UPDATE_LOADING_STATE,
    REQUEST_SUCCESS,
    ADD_PAYROLL_ERROR,
    UPDATE_SELECTED_STAFF,
    SELECT_ALL_STAFF,
    CLEAR_SELECTED_STAFF,
    UPDATE_PAYROLL,

    UPDATE_TEACHER_LOADING_STATE,
    REQUEST_SUCCESS_TEACHER,
    ADD_TEACHERS_ERROR,

    UPDATE_CREATING_PAYROLL_LOADING_STATE,
    REQUEST_SUCCESS_CREATE_PAYROLL,
    ADD_CREATED_PAYROLL,
}


export type PayrollType = 'Paid' | 'Failed' | 'Pending' | 'Processing' | 'Approved';
