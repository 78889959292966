import { Outlet } from "react-router-dom";
import { PayrollProvider } from "../store/payrollContext";

const Payroll = () => {
  return (
    <PayrollProvider>
      <Outlet />
    </PayrollProvider>
  );
};

export default Payroll;
