import { useAuth } from "../../store/authContext";
import { toTitleCase } from "../../utils/helperFunctions";

const SchoolCard = () => {
  const { user } = useAuth();

  const { logoUrl, schoolName } = user;

  return (
    <div className="flex flex-col items-center justify-center gap-[12px] self-stretch rounded-[10px] bg-[#F8F9FB] py-[12px] px-[16px]">
      <img src={logoUrl} className="h-[50px]" alt="School Logo" />
      <p className="text-center font-body text-base font-medium leading-5 ">
        {toTitleCase(schoolName)}
      </p>
    </div>
  );
};

export default SchoolCard;
