import bleauTechLogo from "../../../../assets/images/logo.png";
import { DashboardIcon } from "../../../../components/icons";
import { useAuth } from "../../../../store/authContext";

interface SchoolEmptySideNavProps {
  children: React.ReactNode;
}

const SchoolEmptySideNav = ({ children }: SchoolEmptySideNavProps) => {
  const { user } = useAuth();
  return (
    <div className="flex h-full w-full flex-col justify-between pt-[24px] pb-[58px]">
      <div>
        <div className="mb-[32px] rounded-[5px] px-[56px]">
          <img src={bleauTechLogo} className="h-[35px] w-[145px]" alt="logo" />
        </div>

        <div className="flex h-[46px] w-full items-center justify-start border-l-[4px] border-primary bg-[#F3F9FE] pl-[25px]">
          <span className="mr-[12px] text-[20px]">
            <DashboardIcon />
          </span>
          <span className="text-[15px] font-semibold">Dashboard</span>
        </div>
      </div>

      <div className="mx-[28px] flex h-[106px] flex-col items-center justify-center space-y-[12px] rounded-[10px] bg-[#F8F9FB]">
        <img src={user.logoUrl} className="h-[50px]" alt="School logo" />
        <span className="text-[14px] font-medium">{user.schoolName ?? ""}</span>
      </div>
    </div>
  );
};

export default SchoolEmptySideNav;
