import { PayrollType } from "../types/payrollTypes";
import { debugPrint } from "../../../../utils/debugPrint";

const _payrollStatus = {
  PENDING_APPROVAL: "pendingApproval",
  PROCESSING: "processing",
  COMPLETED: "completed",
  APPROVED: "approved",
  UNAPPROVED: "unapproved",
  // SUCCESS: "success",
  FAILED: "failed",
};

export const payrollStatus = Object.freeze(_payrollStatus);

export const getPayrollStatus = (status: string) => {
  switch (status) {
    case payrollStatus.PENDING_APPROVAL:
      return "Pending";
    case payrollStatus.PROCESSING:
      return "Processing";
    case payrollStatus.COMPLETED:
      return "Completed";
    case payrollStatus.APPROVED:
      return "Approved";
    case payrollStatus.UNAPPROVED:
      return "Unapproved";
    // case payrollStatus.SUCCESS:
    //   return "Success";
    case payrollStatus.FAILED:
      return "Failed";

    default:
      return "Pending";
  }
};

export const getCreatePayrollStatus = (status: string) => {
  debugPrint('payrollUtil - status -- ', status);
  switch (status) {
    case payrollStatus.PENDING_APPROVAL:
      return "Approve payroll";
    case payrollStatus.APPROVED:
      return "Make payment";

    case payrollStatus.FAILED:
      return "Retry payment";

    default:
      return "Prepare payroll";
  }
};

export const getShouldShowPayrollButton = (status: string) => {
  if (status === payrollStatus.COMPLETED) {
    return false;
  }

  return true;
};

export const getPayrollStatusNew = (status: string): PayrollType => {
  if (status === 'completed') {
    return 'Paid';
  }

  if (status === 'failed') {
    return 'Failed';
  }

  if (status === 'approved') {
    return 'Approved';
  }

  if (status === 'processing') {
    return 'Processing';
  }

  return 'Pending';
}


export const getPayrollStatusLightColor = (status: string): string => {
  if (status.toLowerCase().trim() === 'completed') {
    return '#EEFFEB';
  }

  if (status.toLowerCase().trim() === 'failed') {
    return '#FFC1C180';
  }

  return '#FFF8EB';
}

export const getPayrollStatusDarkColor = (status: string): string => {
  if (status.toLowerCase().trim() === 'completed') {
    return '#34C759';
  }

  if (status.toLowerCase().trim() === 'failed') {
    return '#FF3B30';
  }

  return '#EEA23E';
}

