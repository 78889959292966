import {
  createContext,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import payrollReducer, { initialState } from "./payrollReducer";
import {
  getAllSchoolPayrolls,
  getAllSchoolTeachers,
} from "../../services/schoolService";
import { isEmpty } from "../../../../utils/objectHelperFunctions";
import { debugPrint } from "../../../../utils/debugPrint";
import {
  PAYROLL_ACTION_TYPE,
  PayrollAction,
  PayrollState,
} from "../types/payrollTypes";
import { PayrollDto } from "../../../../types/schoolService/schoolServiceTypes";

interface PayrollProviderProps {
  children?: React.ReactNode;
}

interface PayrollContextType {
  state: PayrollState;
  clearSelectedStaff: () => void;
  selectAllStaffIds: (staffIds: string[]) => void;
  updateSelectedStaff: (staffId: string) => void;
  fetchAllTeachers: () => void;
  addPayroll: (payroll: PayrollDto) => void;
  updatePayroll: (payroll: PayrollDto) => void;
}

const PayrollContext = createContext({} as PayrollContextType);

PayrollContext.displayName = "PayrollContext";

const PayrollProvider = ({ children }: PayrollProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<PayrollState, PayrollAction>>(
    payrollReducer,
    initialState
  );

  useEffect(() => {
    const fetchAllSchoolPayroll = async () => {
      dispatch({
        type: PAYROLL_ACTION_TYPE.UPDATE_LOADING_STATE,
        payload: { isLoading: true },
      });

      try {
        const { data } = await getAllSchoolPayrolls();

        const { status, message } = data;

        if (status === true) {
          const { data: payrolls } = data || {};

          if (isEmpty(payrolls)) {
            return dispatch({
              type: PAYROLL_ACTION_TYPE.REQUEST_SUCCESS,
              payload: {
                payrolls: [],
              },
            });
          }

          dispatch({
            type: PAYROLL_ACTION_TYPE.REQUEST_SUCCESS,
            payload: {
              payrolls: [...payrolls],
            },
          });
        } else {
          throw new Error(message ?? "Something went wrong");
        }
      } catch (err) {
        const error = err as Error;
        dispatch({
          type: PAYROLL_ACTION_TYPE.ADD_PAYROLL_ERROR,
          payload: { error: error?.message ?? "Something went wrong" },
        });
      } finally {
        dispatch({
          type: PAYROLL_ACTION_TYPE.UPDATE_LOADING_STATE,
          payload: { isLoading: false },
        });
      }
    };

    fetchAllSchoolPayroll();
  }, []);

  // Function responsible for clearing selected staff in state
  const clearSelectedStaff = () => {
    dispatch({
      type: PAYROLL_ACTION_TYPE.CLEAR_SELECTED_STAFF,
      payload: {
        isLoading: undefined,
        payrolls: undefined,
        error: undefined,
        staffIds: undefined,
        staffId: undefined,
        payroll: undefined,
        isTeacherLoading: undefined,
        teachers: undefined,
        isCreatingPayroll: undefined,
      },
    });
  };

  const selectAllStaffIds = (staffIds: string[]) => {
    dispatch({
      type: PAYROLL_ACTION_TYPE.SELECT_ALL_STAFF,
      payload: { staffIds: staffIds },
    });
  };

  const updateSelectedStaff = (staffId: string) => {
    debugPrint("payrollContent - updatedSelectedStaff -- staffId ->", staffId);
    dispatch({
      type: PAYROLL_ACTION_TYPE.UPDATE_SELECTED_STAFF,
      payload: { staffId: staffId },
    });
  };

  // For creating payroll
  const fetchAllTeachers = async () => {
    dispatch({
      type: PAYROLL_ACTION_TYPE.UPDATE_TEACHER_LOADING_STATE,
      payload: { isTeacherLoading: true },
    });

    try {
      const { data } = await getAllSchoolTeachers();

      const { status, message } = data;

      if (status === true) {
        const { data: teachers } = data;

        dispatch({
          type: PAYROLL_ACTION_TYPE.REQUEST_SUCCESS_TEACHER,
          payload: {
            teachers: [...teachers],
          },
        });
      } else {
        throw new Error(message ?? "Something went wrong");
      }
    } catch (err) {
      const error = err as Error;
      dispatch({
        type: PAYROLL_ACTION_TYPE.ADD_TEACHERS_ERROR,
        payload: {
          teacherError: error?.message ?? "Something went wrong",
        },
      });
    } finally {
      dispatch({
        type: PAYROLL_ACTION_TYPE.UPDATE_TEACHER_LOADING_STATE,
        payload: {
          isTeacherLoading: false,
        },
      });
    }
  };

  const addPayroll = (payroll: PayrollDto) => {
    dispatch({
      type: PAYROLL_ACTION_TYPE.ADD_CREATED_PAYROLL,
      payload: {
        payroll: payroll,
      },
    });
  };

  const updatePayroll = (payroll: PayrollDto) => {
    dispatch({
      type: PAYROLL_ACTION_TYPE.UPDATE_PAYROLL,
      payload: {
        payroll: payroll,
      },
    });
  };

  const values = {
    state,
    clearSelectedStaff,
    updateSelectedStaff,
    selectAllStaffIds,
    fetchAllTeachers,
    addPayroll,
    updatePayroll,
  };

  return (
    <PayrollContext.Provider value={values}>{children}</PayrollContext.Provider>
  );
};

const usePayroll = () => {
  const context = useContext(PayrollContext);
  if (!context) {
    throw new Error("usePayroll must be used within PayrollContext");
  }
  return context;
};

export { PayrollProvider, usePayroll };
