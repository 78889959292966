import * as Icons from "../../components/icons";
import AddExam from "../forms/AddExam";
import Modal from "./Modal";

export const AddExamButton = ({
  classes,
  disabled = false,
  showModal = false,
  onClose,
}) => {
  return (
    <>
      {/* <UndismissibleModal showModal={showExamModal} showCloseButton={false}>
        <AddExam closeModal={shouldShowExamModal} />
      </UndismissibleModal> */}
      <Modal showModal={showModal} onClose={onClose}>
        <AddExam closeModal={onClose} />
      </Modal>
      <button
        className={`inline-flex h-[40px] w-[156px] items-center justify-center whitespace-nowrap rounded-[8px] ${
          disabled ? "bg-grey" : "bg-[#437EF7]"
        }  text-[14px] font-semibold text-white ${classes}`}
        onClick={onClose}
        disabled={disabled}
      >
        <span className="mr-[12px] inline-flex h-[14px] w-[14px]">
          <Icons.Plus className="flex-0 h-full" />
        </span>
        Add Exam
      </button>
    </>
  );
};
