import AddSubjectToClass from "./../forms/AddSubjectToClass";
import Modal from "./Modal";

import { accountType } from "../../../constants/accountType";
import { useAuth } from "../../../store/authContext";

/// [selectedClass] props will be used to determine if the subjects added to a
/// particular class should be returned to the [classContext] to optimistically
/// update the UI
export const AddSubjectToClassButton = ({
  selectedClass,
  showModal = false,
  onClose,
}) => {
  const { user } = useAuth();

  const userType = user?.userType;

  const addSubjectToClassContent = () => {
    return (
      <>
        <Modal showModal={showModal} onClose={onClose}>
          <AddSubjectToClass
            selectedClassProp={selectedClass}
            closeModal={onClose}
          />
        </Modal>
        <button
          className="inline-flex h-[40px] w-[156px] transform items-center justify-center gap-[6px] rounded-[5px] bg-[#437EF7] py-[8px] px-[12px] text-[14px] font-semibold text-white shadow-sm"
          onClick={onClose}
        >
          {/* <span className="mr-[12px] inline-flex h-[14px] w-[14px]">
            <Icons.Plus className="flex-0 h-full" />
          </span> */}
          Assign Subject
        </button>
      </>
    );
  };

  return (
    <>{userType === accountType.SCHOOL ? addSubjectToClassContent() : null}</>
  );
};
