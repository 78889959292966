import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import NavBar from "./NavBar";
import SideNavSelector from "../../../common/SideNavSelector";

import { accountType } from "../../../constants/accountType";
import { useAuth } from "../../../store/authContext";
import SchoolEmptySideNav from "../../../features/school/prerequisite/components/SchoolEmptySideNav";

const Layout = ({ children }) => {
  const { user } = useAuth();

  const {
    userType,
    areSubjectsCreated,
    areSubjectsAssignedToClasses,
    areExamTypesCreated,
    // areResultCustomsCommentCreated,
  } = user || {};

  const [shouldShowSideBar, setShouldShowSideBar] = useState(true);

  useEffect(() => {
    if (
      !areSubjectsCreated ||
      !areSubjectsAssignedToClasses ||
      !areExamTypesCreated
    ) {
      setShouldShowSideBar(false);
    }
  }, [areSubjectsCreated, areSubjectsAssignedToClasses, areExamTypesCreated]);

  return (
    <div className="flex h-screen w-screen items-center bg-[#F3F9FE]">
      <div className="no-scrollbar hidden h-full w-[288px] overflow-y-auto overflow-x-hidden border-r-[1px] bg-white lg:block">
        {!shouldShowSideBar && userType === accountType.SCHOOL ? (
          <SchoolEmptySideNav />
        ) : (
          <SideNavSelector />
        )}
      </div>

      <div className="flex h-full w-full flex-1 flex-col items-start gap-[16px] self-stretch bg-[#F3F9FE] p-[32px]">
        <NavBar className="flex-end flex-start z-[30px] flex self-stretch" />

        <main className={`h-full w-full overflow-y-auto sm:grid xl:pl-[5px]`}>
          {children}
        </main>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
