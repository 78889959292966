import * as React from "react";

const SvgWarningOutlineCircle = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.5 3a21 21 0 1 0 0 42 21 21 0 0 0 0-42Zm0 39a18 18 0 1 1 0-36.001A18 18 0 0 1 24.5 42Z"
      fill="#F2994A"
    />
    <path
      d="M23 12h3v16.5h-3V12Zm1.5 21a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
      fill="#F2994A"
    />
  </svg>
);

export default SvgWarningOutlineCircle;
